<template>
  <div class="headerAll" :class="{enHeader: isEnHeader}">
    <div class="headerTop">
      <div class="headerTopContainer">
        <el-row>
          <el-col :span="10">
            <div v-if="sessionInfoGetter.isLogin=='true'">
              <!-- <el-row>
                <el-col :span="24">
                  <span class="topLeftText">
                    <router-link to="/index">
                      <span>首页</span>
                    </router-link>
                  </span>
                  <span class="com topLeftText">您好，</span>
                  <span class="topLeftText">{{sessionInfoGetter.tel}}</span>
                  <span @click="logout()" class="com exit topLeftText">退出</span>
                </el-col>
              </el-row> -->
            </div>
            <div v-else>
              <el-row>
                <!-- <el-col :span="2">
                  <router-link to="/index">
                    <span>首页</span>
                  </router-link>
                </el-col> -->
                <!-- <el-col :span="2" >
                  <router-link to="/login">
                    <span>登录</span>
                  </router-link>
                </el-col> -->
                <el-col :span="4">
                  <router-link to="/register/info">
                    <span>注册</span>
                  </router-link>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="10" :offset="4">
            <div class="headerTopLeftNav">
              <!-- <div class="navList" v-if="sessionInfoGetter.isLogin=='true'">
                <router-link to="/buyerCenter">
                  <div class="headerTopRight">买家中心</div>
                </router-link>
              </div>
              <div class="navList" v-if="sessionInfoGetter.isLogin=='true'">
                <router-link to="/sellerCenter">
                  <div class="headerTopRight">卖家中心</div>
                </router-link>
              </div> -->
              <!-- <div
                class="navList"
                v-if="sessionInfoGetter.isLogin=='true' && userTypeGetter == '2'"
              >
                <router-link to="/productionSell/cart">
                  <div class="headerTopRight">产能购物车</div>
                </router-link>
              </div>-->
              <!-- <div class="navList" v-if="sessionInfoGetter.isLogin=='true'">
                <router-link to="/capitalCenter/info">
                  <div class="headerTopRight">资产中心</div>
                </router-link>
              </div>
              <div class="navList" v-if="sessionInfoGetter.isLogin=='true'">
                <router-link to="/personalCenter">
                  <div class="headerTopRight">{{ userCenterText }}</div>
                </router-link>
              </div> -->
              <!-- <div class="navList" v-if="sessionInfoGetter.isLogin=='true'">
                <router-link to="/mall/user">
                  <div class="headerTopRight">地址管理</div>
                </router-link>
              </div> -->
              <!-- <div class="navList">
                <div class="headerTopRight">消息中心</div>
              </div>
              <div class="navList">
                <div class="headerTopRight">帮助中心</div>
              </div>
              <div class="navList">
                <div class="headerTopRight">在线客服</div>
              </div>-->
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      userType: window.sessionStorage["userType"],
      isEnHeader: false,
      msgSpanCss: {
        MidLongTermSpanCss: 2,
        LoginSpanCss: 10
      },
      userCenterText: window.common.userCenterText
    };
  },

  computed: {
    ...mapGetters(["sessionInfoGetter", "userTypeGetter"])
  },

  mounted() {
    // this.setLanguageCss();
  },
  methods: {
    show: function() {},
    logout() {
      protocol.param_logout.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocol.param_logout).then(response => {
        let { code, msg, values } = response.data;
        if (code == "0") {
          this.$store.commit("setSessionInfo", "");
          this.$store.commit("setUserType", "");
          this.$store.commit('setSelectFirmApplyCommodity',"")
          sessionStorage.clear();
          this.$router.push("/");
        }
      });
    },
    // 把语言保存到localStorage里
    handleCommand(command) {
      localStorage.setItem("lang", command);
      this.$router.go(0);
    },
    //根据语言改变样式
    setLanguageCss() {
      //防止清缓存后数据消失
      this.$i18n.locale =
        localStorage.lang || navigator.language.slice(-2).toLowerCase();
      switch (localStorage.lang) {
        case "en":
          let msgSpanCss = {
            MidLongTermSpanCss: 5,
            LoginSpanCss: 10
          };
          localStorage.setItem("msgSpanCss", JSON.stringify(msgSpanCss));
          this.msgSpanCss = JSON.parse(localStorage.msgSpanCss);
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.headerAll {
  width: 100%;
  background-color: #f7f7f7;
  // margin-bottom: 20px;
}
.headerTop {
  width: 100%;
  background-color: $headerTopBgColor;
}
.headerTopContainer {
  width: 1300px;
  // width: 80%;
  // min-width: 1300px;
  line-height: 35px;
  margin: 0 auto;
}
.headerTopContainer * {
  font-size: 14px;
  color: #9999a2;
}
.headerTopContainer .topLeftText {
  margin-right: 5px;
}
.headerTopContainer .exit {
  cursor: pointer;
}
.headerTopRight {
  text-align: right;
}
.headerTopLeftNav {
  text-align: right;
  overflow: hidden;
}
.headerTopLeftNav .navList {
  display: inline-block;
  margin-left: 20px;
}
.headerTopLeftNav .navList .router-link-active .headerTopRight {
  color: $commonThemeColor;
}
</style>
