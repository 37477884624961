<template>
  <div class="footer">
    <div class="footerContainer">
      <div class="footerTop">
        <!-- <div class="footerTopItem" v-for="list in cloumnList" :key="list.id">
          <p class="footerTitle">{{ list.cloumnName }}</p>
          <p class="footerText" v-for="item in list.children" :key="item.id">
            <a v-if="item.source" :href="item.source" target="_blank" >{{ item.title }}</a>
            <span v-else>{{ item.title }}</span>
          </p>
        </div> -->
        <!-- <div class="imgCode" style="margin-top:-10px">
          <img src="../../../assets/images/homePage/qrCode.png" alt="" width="120px">
        </div> -->
      </div>
    </div>
    <div class="footerBottom" :class="{ indexFooter: isIndexGetter }">
      <!-- <p class="footerBottomList">
        <span class="item" v-for="item in footerArticleList" :key="item.id" @click="goFooterDetails(item.id)">{{ item.title }}</span>
      </p> -->
      <!-- <p class="copyRight"><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37011202001154"><img src="../../../assets/images/homePage/filingIcon.png" alt="" width="20px" style="vertical-align: sub;">&nbsp;&nbsp;鲁公网安备 37011202001154号&nbsp;&nbsp;</a>&nbsp;&nbsp;{{ copyRight }}</p> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      cloumnList: [],
      footerArticleList: [],
      copyRight: window.common.copyRight
    };
  },
  computed: {
    ...mapGetters(["isIndexGetter"])
  },
  mounted() {
    // const that = this
    // setTimeout(function(){
    //    that.getCloumnAndList();
    // },2000)
    this.getCloumnAndList();
    this.getFooterArticle();
  },
  methods: {
    getCloumnAndList() {
      protocolFwd.param_getContentLowerColumn.param = {
        parentColumnId: window.c.footer,
        page: 0,
        size: 6,
        sort: []
      };
      http.getRes(protocolFwd.param_getContentLowerColumn).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          for (let i = 0; i < value.content.length; i++) {
            this.cloumnList.push({
              cloumnName: value.content[i].name,
              id: value.content[i].id,
              children: []
            });
            protocolFwd.param_getContentTitleList.param = {
              columnId: value.content[i].id,
              page: 0,
              size: 10,
              sort: []
            };
            http
              .getRes(protocolFwd.param_getContentTitleList)
              .then(response => {
                const { code, message, value } = response.data;
                if (code == 0) {
                  this.cloumnList[i].children = value.content;
                } else {
                  this.$EL_MESSAGE(message);
                }
              });
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getFooterArticle() {
      protocolFwd.param_getContentTitleListFooterArticle.param = {
        columnId: window.c.footerArticle,
        page: 0,
        size: 10,
        sort: []
      };
      http
        .getRes(protocolFwd.param_getContentTitleListFooterArticle)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.footerArticleList = value.content;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    goFooterDetails(id) {
      this.$router.push({
        name: "footerDetails",
        params: {
          id: id
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.copyRight {
  margin: 15px;
}
.footer {
  width: 100%;
  // position: fixed;
  // bottom: 0;
  // background-color: $commonBlueColor;
}
.footer .footerContainer {
  // width: 80%;
  // min-width: 1300px;
  width: 1200px;
  margin: 0 auto;
}
.footer .footerContainer .item * {
  color: #ffffff;
}
.footer .footerContainer .item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .footerContainer .item .itemLogo {
  margin-right: 10px;
}
.footer .footerContainer .item .itemText p {
  font-size: 20px;
}
.footer .footerTop {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  line-height: 30px;
  text-align: left;
}
.footer .footerTop .footerTitle {
  font-size: 16px;
  color: #5c5c5c;
}
.footer .footerTop .footerText a,
.footer .footerTop .footerText span {
  color: #333;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}
.footer .footerTop .footerText a:hover,
.footer .footerTop .footerText span:hover {
  border-color: #666666;
}
.footer .footerTop .footerTopItem {
  flex-grow: 1;
}
.footer .footerBottom {
  padding: 20px 0;
  padding-top: 130vh;
  line-height: 30px;
  text-align: center;
  background-color: $commonBlueColor;
}
.footer .indexFooter {
  // background-color: #b84c3e;
  background-color: #05406a;
}
.footer .footerBottom * {
  color: #ffffff;
}
.footer .footerBottom .item {
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
}
</style>
