<template>
  <div class="homeWrapper">
    <!-- <topHeader :class="{header:isIndexGetter}"></topHeader> -->
    <div class="homeContent">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import topHeader from "../base/header/topHeader";
import Footer from "../base/footer/footer";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Home",
  components: {
    topHeader,
    Footer
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isIndexGetter"])
  },
  watch: {
    $route: function(to, from) {
      window.scrollTo(0, 0);
    }
  },
  methods: {}
};
</script>


<style scoped>
.homeContent {
  /* padding-top: 20px; */
  /* min-height: 992px; */
}
.header {
  width: 100%;
  background-color: white;
}
.footer {
  position: relative;
  width: 100%;
  /* top: 50px; */
  /* /* bottom: 0;  */
}
</style>
